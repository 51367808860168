// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-hukum-index-js": () => import("./../../../src/templates/HukumIndex.js" /* webpackChunkName: "component---src-templates-hukum-index-js" */),
  "component---src-templates-index-galeri-js": () => import("./../../../src/templates/IndexGaleri.js" /* webpackChunkName: "component---src-templates-index-galeri-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-index-pejabat-js": () => import("./../../../src/templates/IndexPejabat.js" /* webpackChunkName: "component---src-templates-index-pejabat-js" */),
  "component---src-templates-produk-hukum-js": () => import("./../../../src/templates/produk-hukum.js" /* webpackChunkName: "component---src-templates-produk-hukum-js" */),
  "component---src-templates-profil-pejabat-js": () => import("./../../../src/templates/profil-pejabat.js" /* webpackChunkName: "component---src-templates-profil-pejabat-js" */),
  "component---src-templates-publikasi-index-js": () => import("./../../../src/templates/PublikasiIndex.js" /* webpackChunkName: "component---src-templates-publikasi-index-js" */),
  "component---src-templates-publikasi-js": () => import("./../../../src/templates/publikasi.js" /* webpackChunkName: "component---src-templates-publikasi-js" */),
  "component---src-templates-struktur-organisasi-js": () => import("./../../../src/templates/struktur-organisasi.js" /* webpackChunkName: "component---src-templates-struktur-organisasi-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

